import './packagesPage.mobile.scss';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import * as StM from '../../../models/store';
import * as _ from 'lodash';

import PackageInfo, { IPackageInfoCreditsDefinition } from '../../../components/pages/packages/packageInfo';
import ButtonGroup from '../../../components/UI/button-group';
import { SubscriptionsList } from './subscriptionsList';
import { ISubscriptionOfferStateModel, IPackageSessionTypeStoreState, ICoachFeeTierStoreState } from '../../../models/store';
import Strings from "../../../models/store/strings";

interface IPackagesPageProps {
    init: () => void;
    packages: StM.IPackagesStoreState;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    subscriptionOffers: StM.ISubscriptionOffersState;
    currentSubscriptions: IRecurrentPackageCommitmentDetailsDto[];
    subscriptionDefinitions: StM.ISubscriptionOfferStateModel[];
    sessionTypes: IPackageSessionTypeStoreState[];
    coachFeeTiers: ICoachFeeTierStoreState[];

    go: (url: string) => any;
    openCreditTermsDialog: () => Promise<any>;
    openCheckoutDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => Promise<any>;
    getCurrentSubscriptions: () => Promise<any>;
    openCheckoutSubscriptionDialog: (subscription: StM.ISubscriptionOfferStateModel) => Promise<any>;
    onUserCommitmentCancellation: (id: number) => Promise<any>;
    openCancelConfirmationDialog: (msgKey: string, messageType: string, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => void;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
    closeAlert: () => void;
}

const routeSrv = new SrvM.RouteService();
const ONE_TIME_SUBROUTE = 'one-time';
const SUBSCRIPTIONS_SUBROUTE = 'subscriptions';

class PackagesPage extends React.Component<IPackagesPageProps> {
    private utils = new SrvM.Utils();
    private readonly packageSelectorConfig = [
        { label: 'One-Time Packages', value: ONE_TIME_SUBROUTE },
        { label: 'Subscriptions', value: SUBSCRIPTIONS_SUBROUTE },
    ]
    constructor(props: IPackagesPageProps) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.init();
        this.props.getCurrentSubscriptions();
    }

    private getSortedSubscriptions(
        activeSubscriptions: IRecurrentPackageCommitmentDetailsDto[],
        subscriptionOffers: ISubscriptionOfferStateModel[]
    ) {
        // Extract all unique recurrentPackageOfferIds from subscriptionOffers to filter activeSubscriptions with them
        const currentRecurrentPackageOfferIds = new Set(
            subscriptionOffers.map(offer => offer.id)
        );
        const filteredActiveSubscriptions = activeSubscriptions.filter(subscription =>
            !currentRecurrentPackageOfferIds.has(subscription.recurrentPackageOfferId)
        );

        const enrichedSubscriptionOffers = subscriptionOffers.map(offer => {
            const existingSubscription = activeSubscriptions
                .find(commitment => commitment.recurrentPackageOfferId === offer.id);
            return existingSubscription !== undefined ? existingSubscription : offer;
        });

        const combinedSubscriptions: (IRecurrentPackageCommitmentDetailsDto | ISubscriptionOfferStateModel)[] =
            [...enrichedSubscriptionOffers, ...filteredActiveSubscriptions];

        return combinedSubscriptions;
    }

    render() {
        const sortedPackages = _.sortBy(this.props.packages.definitions, d => d.sortOrder);
        const sortedSubscriptions =
            this.getSortedSubscriptions(this.props.currentSubscriptions, this.props.subscriptionOffers.definitions);
        const isSelectorVisible = sortedPackages.length > 0 && sortedSubscriptions.length > 0;
        const areThereAnyOffers = sortedPackages.length > 0 || sortedSubscriptions.length > 0;
        const defaultSubpage = sortedPackages.length > 0
            ? ONE_TIME_SUBROUTE
            : SUBSCRIPTIONS_SUBROUTE;
        const subpage = routeSrv.getCurrentSubPageName() || defaultSubpage;
        const isOneTime = subpage === ONE_TIME_SUBROUTE;

        return (
            <div className="main-content-wrapper packages-page-mobile">
                <div className="page-title-mobile">Packages</div>
                <div className="sub-title-mobile">Elevate your game. Play more and pay less with our Squash Packages.</div>
                <div className="packages-list-wrapper-mobile">
                {isSelectorVisible && 
                    <ButtonGroup
                        config={this.packageSelectorConfig}
                        value={subpage}
                        select={(v: string) => this.onListTypeSelect(v)}
                    />
                }
                {areThereAnyOffers && (isOneTime
                        ? _.sortBy(this.props.packages.definitions, d => d.sortOrder)
                            .map((item: StM.IPackageDefinitionStoreState, index: number) => {
                                const expirationDate = moment().clone().add(item.expirationTimeSpan.asDays(), 'days');
                                const creditsDefinitions = this.getPackageCreditsDefinitions(item);
                                return (
                                    <PackageInfo key={index}
                                        id={item.id}
                                        title={item.title}
                                        description={item.description}
                                        coachFeeTier={item.coachFeeTier}
                                        expirationDate={expirationDate}
                                        price={item.price}
                                        creditsDefinitions={creditsDefinitions}
                                        enablePurchasing={true}
                                        customSessionTypes={this.props.customSessionTypes}
                                        onBuyClick={(packageId) => this.onBuyPackageClick(packageId)}
                                    />
                                )
                            })
                        : <SubscriptionsList
                            subscriptions={sortedSubscriptions}
                            customSessionTypes={this.props.customSessionTypes}
                            onBuySubscriptionClick={(id) => this.onBuySubscriptionClick(id)}
                            onResubscribeClick={(id) => this.onResubscribeClick(id)}
                            onSubscriptionCancellation={(id, billingPeriod) => this.onSubscriptionCancellation(id, billingPeriod)}
                            sessionTypes={this.props.sessionTypes}
                            coachFeeTiers={this.props.coachFeeTiers}
                            currentSubscriptions={this.props.currentSubscriptions}
                            subscriptionDefinitions={this.props.subscriptionDefinitions}
                        />
                    )}
                </div>
                <a className="terms-conditions" onClick={(e) => this.onCreditTermsClick(e)}>
                    Credit Terms
                </a>
            </div>
        )
    }

    private onCreditTermsClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.openCreditTermsDialog();
    }

    private onBuyPackageClick(packageId: number) {
        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            const formattedPhone = this.utils.formatPhone(this.props.club.phone)
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club, formattedPhone)
            );

            return;
        }

        const packageDef = _.find(this.props.packages.definitions, { id: packageId });
        if (packageDef && this.props.openCheckoutDialog)
            this.props.openCheckoutDialog(packageDef);
    }
    
    private onResubscribeClick(id: number) {
        if (id && this.props.openCheckoutDialog) {
            const latestSubscription = this.props.subscriptionDefinitions.find((subscription) => subscription.id === id);
            latestSubscription.status === StM.RecurrentPackageOfferStatuses.Canceled 
                ? this.props.openAlertDialog(
                    StM.MessagesKey.GenericRequestError,
                    StM.MessageTypes.Error,
                    'Current version of this offer is no more available to purchase.'
                  )
                : this.props.openCheckoutSubscriptionDialog(latestSubscription);
        }
    }

    private onBuySubscriptionClick(id: number) {
        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            const formattedPhone = this.utils.formatPhone(this.props.club.phone)
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club, formattedPhone)
            );
            
            return;
        }
        
        const subscriptionOffer = _.find(this.props.subscriptionOffers.definitions, { id });
        if (subscriptionOffer && this.props.openCheckoutDialog) {
            this.props.openCheckoutSubscriptionDialog(subscriptionOffer);
        }
    }

    private onSubscriptionCancellation(id: number, billingPeriod: string) {
        this.props.openCancelConfirmationDialog( 
            StM.MessagesKey.CancelConfirmation,
            StM.MessageTypes.Error,
            `Are you sure that you want to cancel Subscription? <br /><br /> If yes, then your subscription will be cancelled and you will no longer be billed ${billingPeriod}. Any remaining credits will expire when this subscription period ends.`,
            'Cancel Subscription',
            'Close',
            () => { 
                this.props.onUserCommitmentCancellation(id).then(() => {
                    this.props.getCurrentSubscriptions();
                    this.props.openAlertDialog(
                        StM.MessagesKey.CancelSubscription,
                        StM.MessageTypes.Success,
                        'See you on the courts!'
                ).catch((error) => {
                    throw error;
                })
            }); 
            }
        );
    }

    private onListTypeSelect(newValue: string) {
        this.props.go(`/packages/${newValue}`);
    }

    private getPackageCreditsDefinitions(packageDef: StM.IPackageDefinitionStoreState): Array<IPackageInfoCreditsDefinition> {
        return packageDef.creditsDefinitions.map(c => {
            return {
                id: c.id,
                credits: c.credits,
                title: c.title,
                description: c.description,
                sortOrder: c.sortOrder,
                sessionType: c.packageSessionType.sessionType,
                alias: c.typeAlias
            }
        });
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        packages: state.packages,
        subscriptionOffers: state.subscriptionOffers,
        user: state.user,
        club: state.club,
        customSessionTypes: state.customSessions,
        sessionTypes: state.subscriptionOffers.types,
        coachFeeTiers: state.coachFeeTiers,
        currentSubscriptions: state.subscriptionOffers.currentSubscriptions,
        subscriptionDefinitions: state.subscriptionOffers.definitions
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => dispatch(ActM.PackagesPageActions.init()),
        go: (url: string) => dispatch(ActM.RouteActions.replace(url)),
        getCurrentSubscriptions: () => dispatch(ActM.PackagesActions.getCurrentSubscriptions()),
        openCreditTermsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.TermsAndConditions, { noConfirmation: true })),
        openCheckoutDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => 
            dispatch(ActM.DialogActions.open(StM.DialogNames.PackagePurchase, { 
                packageDefinition, packageDefinitionId: packageDefinition.id 
            })
        ),
        openCancelConfirmationDialog: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => 
            dispatch(ActM.DialogActions.open(StM.DialogNames.CancelConfirmation, { 
                msgKey, messageType, message, acceptButtonText, rejectButtonText, yesCallback 
            })
        ),
        openCheckoutSubscriptionDialog: (subscription: StM.ISubscriptionOfferStateModel) =>
            dispatch(ActM.DialogActions.open(StM.DialogNames.SubscriptionPurchase, {
                subscription, id: subscription.id
            })
        ),
        onUserCommitmentCancellation: (id: number) => dispatch(ActM.PackagesActions.onUserCommitmentCancellation(id)),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message })),
        closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesPage);
