import './dialogs.mobile.scss';
import './packageCheckoutDialog.mobile.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import classNames from 'classnames';

import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM from '../../services';
import { ModalMobile } from '../../components';
import PackageBalanceItem from '../../components/credits/packageBalanceItem';
import Strings from "../../models/store/strings";

let utils = new SrvM.Utils();

interface IPackageCheckoutDialogProps {
    params: any;
    isShown: boolean;
    user: StM.UserStoreState;
    club: StM.ClubStoreState;
    isAuthorized: boolean;
    packageDefinition: StM.IPackageDefinitionStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;

    openAuthDialog: (url: string) => Promise<any>;
    openBillingInfoDialog: () => Promise<any>;
    checkout: (definition: StM.IPackageDefinitionStoreState, params: any) => Promise<any>;
    closeDialog: () => void;
    successAlert: () => Promise<any>;
    openCreditTermsDialog: () => Promise<any>;
    addToBasket: (item: StM.IPackageDefinitionStoreState) => void;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
}

interface IPackageCheckoutDialogState {
    validationError: string;
}

class PackageCheckoutDialogMobile extends React.Component<IPackageCheckoutDialogProps, IPackageCheckoutDialogState> {
    private utils = new SrvM.Utils();

    constructor(props: IPackageCheckoutDialogProps) {
        super(props);
        this.state = { validationError: null };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isShown && this.props.isShown) {
            this.setState({ validationError: null });
        }
    }

    render() {
        if (this.props.isShown) {
            let packageDef = this.props.packageDefinition;

            let price = utils.handleMinPrice(packageDef.price);
            let priceWoSalesTax = price;
            let total = 0;
            let tax = 0;

            let applySalesTax = _.some(this.props.packageDefinition.creditsDefinitions,
                (x) => _.some(this.props.club.salesTaxes, (y) => y.subject.toLowerCase() == x.packageSessionType.sessionType.toLowerCase()));

            if (applySalesTax) {
                tax = price * this.props.club.salesTaxRate / 100;
                price += tax;
            }

            let totalClasses = classNames({
                'summary-wrapper-mobile': true,
                'text-right': true,
                'price-total': !!applySalesTax
            });

            return (
                <ModalMobile classes="your-estimated-charges-modal-mobile checkout-packages-modal-mobile" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.PackagePurchase}>
                    <div className="modal-dialog-header">
                        <div className="title checkout">Checkout Package</div>
                    </div>
                    <div className="modal-dialog-body-block">
                        {this.state.validationError && (
                            <div className="error-message-wrapper">
                                <span className="error-message-title">{this.state.validationError}</span>
                            </div>
                        )}
                        <div className="package-wrapper-mobile">
                            <div className="package-body-mobile">
                                <div className="package-info-wrapper-mobile">
                                    <div className="package-title-mobile mobile-row">
                                        <div className="mobile-col-8">{packageDef.title}</div>
                                        <div className="mobile-col-4 price-mobile">${packageDef.price}</div>
                                    </div>
                                </div>
                                <div className="credit-list-mobile">
                                    {packageDef.creditsDefinitions.map((item: StM.IPackageSessionTypeCreditsDefinitionStoreState, index: number) => {
                                        return (
                                            <PackageBalanceItem key={index}
                                                id={item.id}
                                                title={item.title}
                                                description={item.description}
                                                credits={item.credits}
                                                customSessionTypes={this.props.customSessionTypes}
                                                alias={item.typeAlias}
                                                coachFeeTier={packageDef.coachFeeTier}
                                                sessionType={item.packageSessionType.sessionType}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        {applySalesTax &&
                            <div className="summary-wrapper-mobile text-right">
                                <div className="labels-wrapper-mobile">
                                    <div className="mobile-row label-mobile total-mobile text-right">Price</div>
                                </div>
                                <div className="prices-wrapper-mobile">
                                    <div className="mobile-row label-mobile total-mobile text-right">
                                        ${utils.formatStringPrice(priceWoSalesTax.toString())}
                                    </div>
                                </div>
                            </div>}
                        {applySalesTax &&
                            <div className="summary-wrapper-mobile text-right">
                                <div className="labels-wrapper-mobile">
                                    <div className="mobile-row label-mobile total-mobile text-right">
                                        {this.props.club.salesTaxTitle} (${this.props.club.salesTaxRate}%)
                                </div>
                                </div>
                                <div className="prices-wrapper-mobile">
                                    <div className="mobile-row label-mobile total-mobile text-right">
                                        ${utils.formatStringPrice(tax.toString())}
                                    </div>
                                </div>
                            </div>}
                        <div className={totalClasses}>
                            <div className="labels-wrapper-mobile">
                                <div className="mobile-row label-mobile total-mobile text-right">{applySalesTax ? 'Total' : 'Price'}</div>
                            </div>
                            <div className="prices-wrapper-mobile">
                                <div className="mobile-row price-mobile total-mobile text-right">
                                    ${utils.formatStringPrice(price.toString())}
                                </div>
                            </div>
                        </div>
                        <div className="terms-link-wrapper">
                            <a href="#" className="terms-link" onClick={(e) => this.onCreditTermsClick(e)}>Credit Terms</a>
                        </div>
                    </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-checkout btn-red" onClick={(e) => this.checkoutClick(e)}>Add To Cart</button>
                            <button className="btn-mobile btn-black" onClick={(e) => this.props.closeDialog()}>Cancel</button>
                        </div>
                    </div>
                </ModalMobile>
            );
        }
        return null;
    }

    private closeClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
    }

    private checkoutClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            const formattedPhone = utils.formatPhone(this.props.club.phone)
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club, formattedPhone)
            );

            return;
        }


        this.state = { ...this.state, validationError: null };

        if (!this.props.isAuthorized) {
            let thunk = new SrvM.RouteDialogService();
            let hash = thunk.getDialogHash(StM.DialogNames.PackagePurchase, { packageDefinitionId: this.props.packageDefinition.id });
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else if (!this.props.user.paymentProfileId ||
            this.props.user.paymentProfileStatus !== StM.PaymentProfileStatus.Active) {
            this.props.openBillingInfoDialog();
        } else {
            this.props.closeDialog();
            this.props.addToBasket(this.props.packageDefinition);
            //this.props.checkout(this.props.packageDefinition, this.props.params).then(()=> { this.props.closeDialog(); this.props.successAlert();}).catch((error: string) => this.setState({...this.state, validationError: error}));
        }
    }

    private onCreditTermsClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.openCreditTermsDialog();
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        params: ownProps && ownProps.params ? ownProps.params : null
        , isShown: state.dialogs.packagePurchase.isOpened
        , isAuthorized: state.app.isAuthorized
        , user: state.user
        , club: state.club
        , packageDefinition: state.dialogs.packagePurchase.packageDefinition ||
            (state.dialogs.packagePurchase.packageDefinitionId
                ? _.find(state.packages.definitions, (x) => state.dialogs.packagePurchase.packageDefinitionId == x.id)
                : _.first(state.packages.definitions))
        , customSessionTypes: state.customSessions
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.PackagePurchase)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        checkout: (definition: StM.IPackageDefinitionStoreState, params: any) => dispatch(ActM.PackagePurchaseDialogActions.checkout(definition, params)),
        successAlert: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.PackageCheckoutSuccess, messageType: StM.MessageTypes.Success })),
        openCreditTermsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.CreditTerms)),
        addToBasket: (item: StM.IPackageDefinitionStoreState) => { dispatch(ActM.DialogActions.open(StM.DialogNames.PackageAdded)); dispatch(ActM.BasketActions.add(item)); },
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageCheckoutDialogMobile);