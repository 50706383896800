import '../../dialogs.mobile.scss';
import './createSessionDialog.mobile.scss';

import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import ClassNames from 'classnames';
import { createBrowserHistory } from 'history';

import { StM, ActM, PolM, SrvM } from '../../../../modules';
import { ModalMobile, ModalFooter } from '../../../../components';
import SessionInvitationDialogMobile from '../../sessionInvitationDialog.mobile';
import OpenSessionBoardDialogMobile from '../../openSessionBoardDialog.mobile';
import { ISessionStoreState } from '../../../../models/store';
import { Images, MobileImages } from '../../../../constants';
import { IBaseCreateSessionDialogProps } from './baseCreateSessionDialog';
import { SessionAdditionalServices } from '../sessionServices';
import { SessionPrices } from '../sessionPrices';
import Strings from "../../../../models/store/strings";

let utils = new SrvM.Utils();

interface ICreateSessionDialogProps extends IBaseCreateSessionDialogProps {}

interface ICreateSessionDialogState {
    isDouble?: boolean;
    isPrivate?: boolean
    isPaidByOwner?: boolean;
    payCredits?: boolean;
    isExistSession?: boolean;
    playersNumber: number;
    isInvitationDialogOpened: boolean;
    isOpenSessionBoardDialogOpened: boolean;
    credits: number;
    hasVideo: boolean;
    services: StM.IAddonDefinitionStoreState[];
    errors?: any;
}

export class CreateSessionDialogMobile extends React.Component<ICreateSessionDialogProps, ICreateSessionDialogState> {
    private utils: SrvM.Utils;
    private playersMap: Array<string> = [];
    private playersList: Array<any> = [];
    private availablePlayers: Array<any>;
    private readonly courtTimeService: SrvM.CourtTimeService = new SrvM.CourtTimeService();
    private groupInfo = new PolM.GroupInfoPolicy();
    private validator = new SrvM.ValidationService();

    private searchOptions: any = {
        sex: StM.GendersInputSearchItems[0].key,
        skill: StM.SkillInputSearchItems[0].key,
        type: StM.PlayTypesInputSearchItems[0].key,
        typeValue: StM.PlayTypesInputSearchItems[0].value
    }

    constructor(props: ICreateSessionDialogProps) {
        super(props);
        this.utils = new SrvM.Utils();
        this.initMaxPlayers(this.props.maxPlayerCount);
        this.state = {
            isDouble: false,
            isExistSession: false,
            isPaidByOwner: false,
            payCredits: false,
            playersNumber: this.props.sessionType === StM.BookPageSessionType.Play ? 2 : 1,
            isInvitationDialogOpened: false,
            isOpenSessionBoardDialogOpened: false,
            credits: 0,
            hasVideo: false,
            errors: null,
            services: [],
        };
    }

    public componentDidMount() {
        if (this.props) this.reset();
    }

    componentDidUpdate(prevProps) {
        const isShowingChanged = !prevProps.isShow && this.props.isShow;
        const isSessionTypeChanged = !_.isEqual(prevProps.sessionType, this.props.sessionType);
        const isSessionChanged = !_.isEqual(prevProps.session, this.props.session);
        const isMaxPlayersCountChanged = !_.isEqual(prevProps.maxPlayerCount, this.props.maxPlayerCount);
        const isAuthorizedChanged = !this.props.isAuthorized && prevProps.isAuthorized;
        const isTimeChanged = !_.isEqual(this.props.time, prevProps.time);
        const isSessionParamsChanged = !_.isEqual(prevProps.sessionParams, this.props.sessionParams);
    
        if (isShowingChanged || isSessionTypeChanged || isSessionChanged || isMaxPlayersCountChanged 
            || isAuthorizedChanged || isTimeChanged || isSessionParamsChanged) {
          this.reset(this.props);
          if (this.props.session) {
            this.initParamsFromExistSession(this.props);
          } else if (this.props.temporarySession) {
            this.initParamsFromExistSession(this.props, true);
          }
        }
    
        if (isSessionTypeChanged || isSessionChanged || isMaxPlayersCountChanged) {
          this.initMaxPlayers(this.props.maxPlayerCount);
        }
      }

    componentWillUnmount() {
        this.reset();
    }

    render() {
        if (!this.props.isShow || !this.props.court || !this.props.time) return null;
        return this.renderDialog();
    }

    private renderDialog() {
        const availableUsers = _.filter(this.props.users, { isAvailableForInvitation: true });
        this.availablePlayers = this.getInputSearchUsers(availableUsers);
        this.availablePlayers = this.availablePlayers.filter((item) => item.key != this.props.user.id);

        if (!this.isGroup() && this.playersList.length > (this.state.playersNumber - 1)) {
            this.playersList = this.playersList.slice(0, this.state.playersNumber - 1);
        }

        const sessionTypeClassName = this.props.session && this.props.session.type
            ? new SrvM.Utils().getFilterTypeBySessionType(this.props.session.type)
            : this.props.sessionType;
        const dialogClasses = ClassNames(`${sessionTypeClassName} booking player-${this.state.playersNumber}`,
            "show-coach", {
                "club-credits": this.state.payCredits,
                "modal-hidden": this.state.isInvitationDialogOpened || this.state.isOpenSessionBoardDialogOpened
            });

        return (
            <div>
                <ModalMobile classes={dialogClasses} dialogName={StM.DialogNames.NewSession}>
                    {this.renderDialogHeader()}
                    {this.renderDialogSessionSelector()}
                    {this.renderDialogInfoBlock()}
                    {this.renderInvitationBlock()}
                    {this.renderOpenBoardBlock()}
                    {this.isGroup() ? null : this.renderDialogPaymentBlock()}
                    <SessionAdditionalServices 
                        activeServices={this.state.services} 
                        onChange={this.handleServicesChange}
                        session={this.getSessionFromState()}
                    />
                    {this.renderDialogFooter()}
                </ModalMobile>
                <SessionInvitationDialogMobile
                    isShow={this.state.isInvitationDialogOpened}
                    maxPlayerCount={this.isGroup() ? this.state.playersNumber : this.props.maxPlayerCount - 1}
                    session={this.getSessionFromState()}
                    sessionType={this.props.sessionType}
                    user={this.props.user}
                    users={this.props.users}
                    playersList={this.playersList}
                    selectPlayer={(array) => this.selectPlayers(array)}
                    handlePlayerRemove={(id) => this.handlerRemovePlayer(id)}
                    closeInvitationDialog={() => this.closeInvitationDialog()} 
                    isGroup={this.isGroup()}
                />
                <OpenSessionBoardDialogMobile
                    isShow={this.state.isOpenSessionBoardDialogOpened}
                    isPrivate={this.state.isPrivate}
                    sessionType={this.props.sessionType}
                    sex={StM.GendersInputSearchItems}
                    skills={StM.SkillInputSearchItems}
                    searchOptions={this.searchOptions}
                    typesOfPlay={StM.PlayTypesInputSearchItems}
                    onPrivateClick={(e) => this.onPrivate(e)}
                    offPrivateClick={(e) => this.offPrivate(e)}
                    handlePrivateClick={() => this.handlePrivate()}
                    closeOpenSessionBoardDialog={() => this.closeOpenSessionBoardDialog()}
                    selectSearchOptions={(item, option) => this.selectSearchOptions(item, option)}
                    typePlayHandler={(e) => this.typePlayHandler(e)} />
            </div>
        )
    }

    private renderDialogHeader() {
        const sessionType = this.utils.getSessionTypeByFilterType(this.props.sessionType);
        const sessionTitle = this.utils.getSessionTypeTitleByType(sessionType);
        const headerClasses = ClassNames('modal-dialog-header', sessionTitle.toLowerCase());
        return (
            <div className={headerClasses}>
                <div className="title">Book a {sessionTitle}</div>
            </div>
        );
    }

    private renderDialogSessionSelector() {
        const filter: any = {
            sessionType: this.props.sessionType
            , lessonSubfilterId: this.props.sessionParams.coachId
        };
        const coachAvailabilityPolicy = new PolM.CoachAvailabilityPolicy(
            this.props.sessions.concat(this.props.basketSessions)
            , this.props.availableTimes
            , this.props.date
            , this.props.time
            , filter
            , this.props.session ? this.props.session.isDoubledSession : false
        );
        const duration = this.getSessionDuration();
        const singleDuration = this.state.isDouble ? duration / 2 : duration;
        const doubleDuration = !this.state.isDouble ? duration * 2 : duration;
        const isAllowDoubleForCoach = coachAvailabilityPolicy.handleForDouble();
        const isAllowDouble = (this.props.session && this.props.session.isDoubledSession)
            || (this.props.time.isAvailableDoubleCreation && isAllowDoubleForCoach && this.props.next);
        const doubleSessionClasses = ClassNames("form-check-label", { disabled: !isAllowDouble });
        return (
            <div className="modal-dialog-body-block">
                <div className="form-wrapper">
                    <div className="radio-wrapper">
                        <div className="radio-item">
                            <label className="form-check-label">
                                <input type="radio" className="form-check-input"
                                    name="session-period" value="single"
                                    checked={!this.state.isDouble} hidden onChange={(e) => this.offDouble(e)} />
                                <span className="form-check-text">SINGLE SESSION ({singleDuration} min)</span>
                            </label>
                        </div>
                        <div className="radio-item">
                            <label className={doubleSessionClasses}>
                                <input type="radio" className="form-check-input"
                                    name="session-period" value="double" hidden
                                    checked={this.state.isDouble} onChange={(e) => this.onDouble(e)} disabled={!isAllowDouble} />
                                <span className="form-check-text">DOUBLE SESSION ({doubleDuration} min)</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderDialogInfoBlock() {
        return (
            <div className="modal-dialog-body-block">
                <div className="info-wrapper-mobile">
                    {this.renderDateBlock()}
                    {this.renderCoach()}
                    {this.renderPlayersBlock()}
                </div>
            </div>
        );
    }

    private renderDateBlock() {
        const timesRange = this.utils.getCourtTimeBlockTimesRange(this.props.time, this.getSessionDuration());

        return (
            <div className="date-wrapper-mobile">
                <div className="mobile-row">
                    <div className="date mobile-col-12">
                        <span className="day">{this.props.date.date()} </span>
                        <span className="month">{moment.monthsShort(this.props.date.month()) + ','}</span>
                    </div>
                </div>

                <div className="mobile-row flex-baseline-mobile">
                    <div className="time-range-wrapper-mobile mobile-col-8">
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{timesRange.start.time}</div>
                            <div className="time-type-mobile">{timesRange.start.format}</div>
                        </div>
                        <div className="time-divider-mobile">&ndash;</div>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{timesRange.end.time}</div>
                            <div className="time-type-mobile">{timesRange.end.format}</div>
                        </div>
                    </div>
                    <div className="court-wrapper-mobile mobile-col-4 right">
                        <div className="court-number-mobile">
                            {this.utils.getReadableCourtTitle(this.props.court.title)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderPlayersBlock() {
        return (
            <div className="players-wrapper-mobile">
                <div className="title">Players</div>
                <div className="mobile-row">
                    {
                        this.playersMap.map((item, index) => {
                            const playersItemClasses = ClassNames("players-item mobile-col", {
                                choosed: this.state.playersNumber == (index + 1)
                            });
                            const itemStyle = { "width": `${100 / this.props.maxPlayerCount}%` };
                            return (
                                <div className={playersItemClasses}
                                    key={index}
                                    style={itemStyle}
                                    onClick={(e) => this.onChosePlayerNumberClick(e, index + 1)}>
                                    {item}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    private renderInvitationBlock() {
        if (!this.isGroup() && this.state.playersNumber <= 1) return null;
        const invitedPlayers = this.playersList ? this.playersList.length : 0;
        const statusClasses = ClassNames("status-mobile", { active: invitedPlayers > 0 });
        return (
            <div className="modal-dialog-body-block interactive-block-mobile" onClick={(e) => this.openInvitationDialog(e)}>
                <div className="interactive-block-wrapper-mobile mobile-row">
                    <div className="caption-mobile mobile-col-6">INVITE PLAYERS</div>
                    <div className="status-wrapper-mobile mobile-col-6">
                        <span className={statusClasses}>{invitedPlayers} Invited</span>
                        <img src={MobileImages.RedArrow} alt="" />
                    </div>
                </div>
            </div>
        );
    }

    private renderOpenBoardBlock() {
        if (this.isGroup || this.state.playersNumber <= 1) return null;
        let statusClasses = ClassNames("status-mobile", { "active": !this.state.isPrivate });
        return (
            <div className="modal-dialog-body-block interactive-block-mobile" onClick={(e) => this.openSessionBoardDialog(e)}>
                <div className="interactive-block-wrapper-mobile mobile-row">
                    <div className="caption-mobile mobile-col-6">PRIVACY</div>
                    <div className="status-wrapper-mobile mobile-col-6">
                        <span className={statusClasses}>{this.state.isPrivate ? "Private" : "Public"}</span>
                        <img src={MobileImages.RedArrow} alt="" />
                    </div>
                </div>
            </div>
        );
    }

    private renderDialogPaymentBlock() {
        let isLessonSession = this.props.sessionType == StM.BookPageSessionType.Lesson;
        let playersNumber = this.state.playersNumber;
        let credits = this.props.time.credits;
        let showPaymentMethodsBlock = !!credits && (isLessonSession ? playersNumber == 1 : true);
        return (!!credits || playersNumber > 1) ? (
            <div className="modal-dialog-body-block">
                <div className="payment-wrapper-mobile">
                    {showPaymentMethodsBlock && (
                        <div className="payment-method-wrapper-mobile">
                            <div className="title">Payment Method</div>
                            <div className="form-wrapper">
                                <div className="radio-wrapper">
                                    <div className="radio-item">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input"
                                                name="payment-method" value="option1"
                                                checked={!this.state.payCredits} hidden onChange={(e) => this.payChargeCardhRadioHandler(e)} />
                                            <span className="form-check-text">Charge Card</span>
                                        </label>
                                    </div>
                                    <div className="radio-item club-credits-mobile">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input"
                                                name="payment-method" value="option2" hidden
                                                checked={this.state.payCredits} onChange={(e) => this.payCreditsRadioHandler(e)} />
                                            <span className="form-check-text">Club Credits</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {playersNumber > 1 && (
                        <div className="payment-sharing-wrapper-mobile">
                            <div className="title">Payment Sharing</div>
                            <div className="form-wrapper">
                                <div className="radio-wrapper">
                                    <div className="radio-item">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input" name="payment-sharing" value="false" hidden checked={!this.state.isPaidByOwner} onChange={(e) => this.togglePaidOption(e, false)} />
                                            <span className="form-check-text">Invite player to share cost</span>
                                        </label>
                                    </div>
                                    <div className="radio-item">
                                        <label className="form-check-label">
                                            <input type="radio" className="form-check-input" name="payment-sharing" value="true" hidden checked={this.state.isPaidByOwner} onChange={(e) => this.togglePaidOption(e, true)} />
                                            <span className="form-check-text">Pay for entire session</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    }

    private renderDialogFooter() {
        return (
            <div className='book-submit-modal'>
                <ModalFooter errors={this.state.errors} errorMessage={this.state.errors && this.state.errors.addedPlayers}>
                    <div className="modal-dialog-body-block">
                        <SessionPrices session={this.getSessionFromState()} pricingTier={this.props.time.pricingTier} />
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-book btn-red" onClick={(e) => this.bookSubmit(e)}>Add to Cart</button>
                        </div>
                    </div>
                </ModalFooter>
            </div>
        )
    }

    private renderCoach() {
        let coachId = this.props.sessionParams.coachId;
        let coach = _.find(this.props.coaches, (i) => i.id === coachId);
        if (!coach) return null;
        let imageUrl = coach.imageId ? this.utils.getImageUrl(coach.imageId) : Images.NoPhoto2;
        return (
            <div className="coach-wrapper-mobile flex-center-mobile">
                <div className="coach-photo-mobile">
                    <img src={imageUrl} alt={coach.displayName} />
                </div>
                <span className="coach-name-mobile text-ellipsis">{coach.displayName}</span>
            </div>
        );
    }

    private validatePlayers(): boolean {
        if(this.isGroup() && !this.playersList.length) {
            const errors = this.validator.setError('addedPlayers', 'Please add a host');
            this.setState({...this.state, errors});
            return false;
        }
        return true;
    }

    private initParamsFromExistSession(props: ICreateSessionDialogProps, isTemporary: boolean = false, keepServices: boolean = false) {
        const session = isTemporary ? props.temporarySession : props.session;
        let addedPlayers: StM.IAddedUserStoreState[] = [];
        if(this.isGroup()) {
            addedPlayers = session && session.bookings 
            ? props.users
                .filter((user) => session.bookings.some(b => b.userId === user.id && this.utils.isActiveBooking(session, b) 
                && (!session.addedUsers.length || !_.some(session.addedUsers, (u) => u.id == b.userId))))
                .map(user => {
                    
                    const booking = session.bookings.find(b => b.userId === user.id);
                    const paymentType = !!booking 
                        ? booking.paymentType 
                        : (session.checkoutCredits ? StM.PaymentTypes.Credits : StM.PaymentTypes.Charge);
                    return new StM.AddedUserStoreState({
                        ...user,
                        paymentType
                    });
                })
            : [];
            addedPlayers.push(...session.addedUsers.filter((item) => !addedPlayers.some(p => p.id === item.id)));
        }
        const credits = session.credits || session.checkoutCredits;
        this.setState({
            ...this.state,
            isDouble: !!session.isDoubledSession,
            isExistSession: !isTemporary,
            isPaidByOwner: session.isPaidByOwner,
            playersNumber: session.maxUserCount,
            payCredits: !!credits,
            credits: credits,
            isPrivate: session.isHidden,
            services: keepServices ? this.state.services : utils.getSessionServices(session, !this.isGroup() && props.user),
        }, () => {
            if (session && session.playerQualification && session.seeking) {
                let sex: any = StM.GendersInputSearchItems.find(item => item.value === session.playerQualification.sex);
                let skill: any = StM.SkillInputSearchItems.find(item => item.value === session.playerQualification.skill);
                let typeOfPlay: any = StM.PlayTypesInputSearchItems.find(item => item.value === session.seeking.type);
                let description = session.seeking.description;
                if (sex) { sex = sex.key; }
                if (skill) { skill = skill.key; }
                if (typeOfPlay) { typeOfPlay = typeOfPlay.key; }
    
                this.searchOptions = {
                    sex: sex,
                    skill: skill,
                    type: typeOfPlay,
                    typeValue: description
                };
            }
        });
    }

    private initMaxPlayers(maxPlayerCount: number) {
        const maxPlayers = maxPlayerCount;
        this.playersMap = [];
        for (let i = 0; i < maxPlayers; i++) {
            let val = i + 1;
            this.playersMap.push(val.toString());
        }
    }

    private reset(props: ICreateSessionDialogProps = this.props) {
        const pricesPolicy = new PolM.PricesPolicy(!!props.session && props.session.isDoubledSession, props.session);
        const prices = pricesPolicy.handle();
        const credits = prices.credits;        this.updateState({
            isInvitationDialogOpened: false,
            isOpenSessionBoardDialogOpened: false,
            isDouble: false,
            payCredits: !!credits && (credits > 0),
            isPaidByOwner: false,
            isExistSession: false,
            playersNumber: props.sessionType === StM.BookPageSessionType.Play ? 2 : 1,
            credits,
            isPrivate: true,
            hasVideo: false,
            services: [],
        }, props);

        this.playersList = [];
        this.searchOptions = {
            sex: StM.GendersInputSearchItems[0].key,
            skill: StM.SkillInputSearchItems[0].key,
            type: StM.PlayTypesInputSearchItems[0].key,
            typeValue: StM.PlayTypesInputSearchItems[0].value
        };
    }

    private onChosePlayerNumberClick(e: any, i: number) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.isGroup() ? this.playersList = [] : null;
        this.updateState({ ... this.state, playersNumber: i }, this.props);
    }

    private handlePrivate() {
        this.setState({ ...this.state, isPrivate: !this.state.isPrivate });
    }

    private getBookTime(date: moment.Moment, time: moment.Duration) {
        const clone = date.clone().startOf('day');
        const datetime = clone.set({ 'hour': time.hours(), 'minute': time.minutes() });
        return datetime;
    }

    private handlerRemovePlayer(id: string) {
        this.playersList = this.playersList.filter(p => p.id !== id);
        this.forceUpdate();
    }

    // selects handler

    private selectPlayers(array: Array<any>) {
        let players: Array<any> = [];
        for (let i = 0; i < array.length; i++) {
            let item = array[i];
            let user: any;
            for (let j = 0; j < this.props.users.length; j++) {
                let _user = this.props.users[j];
                if (_user.id == item.key) {
                    user = _user;
                    break;
                }
            }
            if (user) {
                players.push(user);
            }
        }
        this.playersList = players;
        this.forceUpdate();
    }

    private selectSearchOptions(item: any, option: string) {
        this.searchOptions[option] = item.key;
        if (option == 'type') {
            this.searchOptions['typeValue'] = item.value;
        }
        this.forceUpdate();
    }

    private typePlayHandler(e: any) {
        this.searchOptions.typeValue = e.target.value;
        this.forceUpdate();
    }

    private onPrivate(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isPrivate: true });
    }
    private offPrivate(e: any) {
        if (e) e.preventDefault();
        this.setState({ ...this.state, isPrivate: false });
    }
    private onDouble(e: any) {
        if (!this.props.next) return false;
        this.updateState({ ...this.state, isDouble: true }, this.props);
    }
    private offDouble(e: any) {
        if (!this.props.next) return false;
        this.updateState({ ...this.state, isDouble: false }, this.props);
    }

    private togglePaidOption(e: any, value: boolean) {
        this.updateState({ ...this.state, isPaidByOwner: value }, this.props);
    }

    private payChargeCardhRadioHandler(e: any) {
        this.updateState({ ...this.state, payCredits: false }, this.props);
    }

    private payCreditsRadioHandler(e: any) {
        this.updateState({ ...this.state, payCredits: true, isDouble: false }, this.props);
    }

    private onSwitch(value: boolean, field: string) {
        this.updateState({ ...this.state, [field]: value }, this.props);
    }

    private updateState(nextState: ICreateSessionDialogState, nextProps: ICreateSessionDialogProps = this.props) {
        const paymentTypeChanged = this.state.payCredits != nextState.payCredits;
        const doubleSessionSelectionChanged = this.state.isDouble != nextState.isDouble;
        const paidByOwnerSelectionChanged = this.state.isPaidByOwner != nextState.isPaidByOwner;
        const playersNumberSelectionChanged = this.state.playersNumber != nextState.playersNumber;

        const credits = 0 //BaseCreateSessionDialog.calculateCreditsFromState(nextProps);

        if (credits && paymentTypeChanged && nextState.payCredits) {
            this.setState({
                ...nextState, 
                isDouble: false, 
                isPaidByOwner: nextProps.sessionType === StM.BookPageSessionType.Play ? this.state.isPaidByOwner : false,
                playersNumber: nextProps.sessionType === StM.BookPageSessionType.Lesson ? 1 : nextState.playersNumber, 
                credits
            });
        }
        else if ((doubleSessionSelectionChanged && nextState.isDouble)
            || (paidByOwnerSelectionChanged && nextState.isPaidByOwner && nextProps.sessionType != StM.BookPageSessionType.Play)
            || (playersNumberSelectionChanged && nextProps.sessionType == StM.BookPageSessionType.Lesson)
        ) {
            this.setState({ ...nextState, credits: 0, payCredits: false });
        }
        else this.setState({ ...nextState, credits: 0/* BaseCreateSessionDialog.calculateCreditsFromState(nextProps)*/ });
    }

    private bookSubmit(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        if (
            !this.props.user.isMinorFormSigned &&
            this.props.user.dateOfBirth && utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)
        ) {
            const formattedPhone = utils.formatPhone(this.props.club.phone)
            this.props.openAlertDialog(
                StM.MessagesKey.KidsProtectionPolicy,
                StM.MessageTypes.Warning,
                Strings.getKidsProtectionPolicyMessage(this.props.club, formattedPhone)
            );

            return;
        }

        if (!this.validatePlayers()) return false;
        
        const session = this.getSessionFromState();

        this.addToBasket(session).then(() => {
            this.handleClose();
        });
    }

    private addToBasket(session: StM.ISessionStoreState) {
        if (!this.props.isAuthorized) {
            return new Promise((resolve, reject) => {
                let hash = window.location.hash;
                let url = encodeURIComponent(hash);
                this.props.openAuthDialog(url);
                if(this.props.saveTemporarySession){
                    this.props.saveTemporarySession(session);
                }
            });
        } else {
            return new Promise<void>((resolve, reject) => {
                this.reset();
                this.props.addSessionToBasket(session);
                return resolve();
            });
        }
    }

    private openInvitationDialog(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ ...this.state, isInvitationDialogOpened: true });
    }

    private closeInvitationDialog() {
        this.setState({ ...this.state, isInvitationDialogOpened: false });
    }

    private openSessionBoardDialog(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ ...this.state, isOpenSessionBoardDialogOpened: true });
    }

    private closeOpenSessionBoardDialog() {
        this.setState({ ...this.state, isOpenSessionBoardDialogOpened: false });
    }

    private getInputSearchUsers(array: Array<any>) {
        return array.map(item => {
            return {
                key: item.id,
                value: item.displayName
            }
        });
    }

    private handleClose() {
        if(this.props.closePlayBookingDialog) {
            this.props.closePlayBookingDialog();
        }

        if(this.props.temporarySession && this.props.clearTemporarySession){
            this.props.clearTemporarySession();
        } 
    }

    private getSessionDuration(): number {
        if(!this.props.time) return 0;
        const existedSession = this.state.isExistSession && this.props.time.session;
        let duration = this.props.time.duration;
        if(this.state.isDouble && (!existedSession || !existedSession.isDoubledSession)) {
            duration *= 2;
        } else if(!this.state.isDouble && !!existedSession && existedSession.isDoubledSession) {
            duration /= 2;
        }
        return duration;
    }

    private isGroup() {
        return this.groupInfo.getIsAuthenticated();
    }

    private handleServicesChange = (services: StM.IAddonDefinitionStoreState[]) => {
        this.setState({...this.state, services });
    }

    private getBookingsFromAddedUsers(addedUsers: StM.IAddedUserStoreState[]) : Array<StM.IBookingStoreState>{
        return addedUsers.map(user => 
            {
                const booking = new StM.BookingStoreState();
                booking.user = new StM.UserStoreState
                booking.user.id = booking.userId = user.id;
                booking.paymentType = user.paymentType;
                booking.status = StM.BookingStatus.New;
                booking.groupId = this.isGroup() && this.props.user.group.id;

                return booking;
            });
    }

    private getSessionFromState(): StM.ISessionStoreState {
        const time = this.props.time;
        if(!time) return new StM.SessionStoreState();

        const duration = this.getSessionDuration();
        const startTime = time.start;
        const endTime = moment.duration(time.start).add(duration, 'm');
        const addedPlayers = this.playersList;
        const owner = (this.isGroup() && _.first(addedPlayers)) || this.props.user;
        const session = this.props.session || new StM.SessionStoreState({
            type: this.utils.getSessionTypeByFilterType(this.props.sessionType),
            court: this.props.court,
            courtId: this.props.court ? this.props.court.id : null,
            club: this.props.club,
            clubId: this.props.club ? this.props.club.id : null,
            minUserCount: 1,
        });
        session.owner = owner;
        session.ownerId = owner.id;
        session.maxUserCount = this.state.playersNumber;
        session.invitedUsers = [...this.playersList];
        session.addedUsers = addedPlayers;
        session.startDateTime = this.getBookTime(this.props.date, startTime);
        session.endDateTime = this.getBookTime(this.props.date, endTime);
        session.isPaidByOwner = this.state.isPaidByOwner;
        session.isHidden = this.state.isPrivate;
        session.isDoubledSession = this.state.isDouble;
        
        if (this.props.time.pricingTier) {
            session.pricingTierId = this.props.time.pricingTier.id;
            session.pricingTierType = this.props.time.pricingTier.type;
        }

        if(!this.state.isPrivate) {
            let sex: string;
            let skill: string;
            let description: string;
            let type: string;

            if (this.searchOptions.sex) {
                sex = StM.GendersInputSearchItems.find((i) => i.key === this.searchOptions.sex).value;
            }
            if (this.searchOptions.skill) {
                skill = StM.SkillInputSearchItems.find((i) => i.key === this.searchOptions.skill).value;
            }
            if (this.searchOptions.type) {
                type = StM.PlayTypesInputSearchItems.find((i) => i.key === this.searchOptions.type).value;
                description = this.searchOptions.typeValue;
            }

            session.playerQualification = new StM.PlayerQualificationStoreState({
                sex: sex,
                skill: skill
            });

            session.seeking = new StM.SeekingStoreState({
                type: type,
                description: description,
                owner: session.owner
            })
        }

        if(this.props.sessionType === StM.BookPageSessionType.Lesson) {
            const coach = this.props.coaches.find((c) => c.id === this.props.sessionParams.coachId);
            session.trainer = coach;
            session.trainerId = coach.id;
        }

        if(this.isGroup()){
            session.bookings = this.getBookingsFromAddedUsers(session.addedUsers);
        }

        session.credits = this.state.payCredits ? time.credits : 0;
        session.recordVideo = this.state.services.some(s => s.alias === StM.ServiceAlias.Video);
        utils.updateSessionServices(session, this.state.services);

        return session;
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any): ICreateSessionDialogProps => {
    let props: ICreateSessionDialogProps = {
        isShow: state.dialogs.сreateSession.isOpened
        , sessionParams: {}
        , sessionId: null
        , session: null
        , isAuthorized: state.app.isAuthorized
        , temporarySession: state.temporarySession
    };

    const history = createBrowserHistory()
    let path = history.location.pathname;
    
    if (state.pages.book.timeSlots) {
        if (state.dialogs.сreateSession.sessionId) {
            props.sessionId = +state.dialogs.сreateSession.sessionId;
            let bSession = _.find(state.basket.goods, { basketId: props.sessionId })
            props.session = bSession ? { ...bSession as ISessionStoreState } : null;
        }
        let timeSlot = _.find(state.pages.book.timeSlots, s => s.court.id == state.dialogs.сreateSession.courtId);
        if (timeSlot) {
            props.court = timeSlot.court;
            props.date = timeSlot.date;
            let timeBlock: any;
            for (let i = 0; i < timeSlot.timeBlocks.length; i++) {
                let block = timeSlot.timeBlocks[i];
                if (block.key.toUpperCase() == state.dialogs.сreateSession.timeKey.toUpperCase()) {
                    timeBlock = block;
                    let next = timeSlot.timeBlocks[i + 1];
                    if (next && next.isAvailableTime && next.isFilter && !next.session) {
                        props.next = next;
                    }
                    break;
                }
            }
            if (timeBlock) {
                props.time = timeBlock;
            }


        }
    }

    let sessionType = state.dialogs.сreateSession.sessionType;
    if (sessionType) {
        props.sessionType = sessionType;
        var type = !!props.session && !!props.session.type
            ? props.session.type
            : new SrvM.Utils().getSessionTypeByFilterType(sessionType)
            ;
        props.maxPlayerCount = PolM.SessionInfoPolicy.getMaxPlayerCount(type);
        if (sessionType = StM.BookPageSessionType.Lesson) {
            let subFilterId = state.dialogs.сreateSession.sessionSubFilterId;
            props.sessionParams.coachId = subFilterId;
        }
    }
    props.sessions = state.pages.book.sessions;
    props.basketSessions = state.basket.goods;
    props.availableTimes = state.pages.book.availableTimes;

    props.user = state.user;
    props.club = state.club;
    props.coaches = state.coaches;
    props.coachFeeTiers = state.coachFeeTiers;
    props.court = _.find(state.club.courts, { id: state.dialogs.сreateSession.courtId });
    props.users = state.activeUsers || [];

    return props;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closePlayBookingDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.NewSession)),
        addSessionToBasket: (item: StM.SessionStoreState) => { dispatch(ActM.BasketActions.add(item)); dispatch(ActM.DialogActions.open(StM.DialogNames.SessionCreateSuccess, { isExist: false })) },
        editBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.edit(item)),
        removeBasketSession: (item: StM.SessionStoreState) => dispatch(ActM.BasketActions.removeById(item.basketId)),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        saveTemporarySession: (session: StM.ISessionStoreState) => dispatch(ActM.SessionActions.saveTemporarySession(session)),
        clearTemporarySession: () => dispatch(ActM.SessionActions.clearTemporarySession()),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSessionDialogMobile);