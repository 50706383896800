import './dialogs.scss';
import './dialogs.mobile.scss';
import './alertDialog.mobile.scss';

import ClassNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../../models/store';
import * as ActM from '../../actions';
import { ModalMobile } from '../../components/modal.mobile';
import { marked } from 'marked';


interface IAlertDialogProps {
    isShown: boolean;
    messageType: string;
    title: string;
    note: string;
    isOpenByService?: boolean;

    closeDialog: (isOpenByService?: boolean) => void;
    showSpinner: () => any;
    hideSpinner: () => any;
}

interface IAlertDialogState {}

class AlertDialogMobile extends React.Component<IAlertDialogProps, IAlertDialogState> {
    constructor(props: IAlertDialogProps) {
        super(props);
    }

    render() {
        if (this.props.isShown) {
            const messageType = this.props.messageType || StM.MessageTypes.Success || StM.MessageTypes.Warning || StM.MessageTypes.Error;
            const rootClasses = ClassNames('alert-dialog-mobile', messageType)
            const titleClasses = ClassNames('title', messageType);
            return (
                <ModalMobile customZIndex={999999} classes={rootClasses} closeHandler={() => this.props.closeDialog(this.props.isOpenByService)} dialogName={StM.DialogNames.Alert} showHeader={false}>
                    <div className="modal-dialog-content-mobile">
                        <div className="modal-dialog-header">
                            <div className={titleClasses}><span>{this.props.title}</span></div>
                        </div>
                        <div className="modal-dialog-body">
                            {this.renderNote(this.props.note)}
                        </div>
                    </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-black btn-close" onClick={(e) => this.closeClick(e)}>Close</button>
                        </div>
                    </div>
                </ModalMobile>
            );
        } else {
            return null;
        }
    }

    renderNote(string:string) {
        if(string) {
            const strippedString = string.replace(/<\/?p>/g, '');
            const htmlString = marked(strippedString);

            return <div className="note-mobile" dangerouslySetInnerHTML={{__html: htmlString}}></div>
        } 
        return null;
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        const prom = new Promise((resolve, reject)=>{
            setTimeout(()=>{
                this.props.closeDialog(this.props.isOpenByService);
                return resolve();
            }, 200);
        });
        this.props.showSpinner();
        prom.then(()=>{
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.alert;
    let title = StM.Messages[dialog.msgKey] || 'Success!';
    let note = dialog.message ? `<p>${dialog.message}</p>` : StM.Notes.getNote(dialog.msgKey, state.club);
    return {
        isShown: dialog.isOpened,
        messageType: dialog.messageType,
        title: title,
        note: note,
        isOpenByService: dialog.isOpenByService,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: (isOpenByService?: boolean) => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, isOpenByService))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialogMobile);
