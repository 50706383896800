import '../dialogs.mobile.scss';
import './authDialog.mobile.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import moment from 'moment';

import { ModalMobile } from '../../../components/modal.mobile';
import * as SrvM from '../../../services';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import DatePicker from '../../../components/datepicker';
import { Field, FormError } from '../../../components';
import Strings from "../../../models/store/strings";

export interface AuthDialogProps {
    isShow: boolean;
    tab: string;
    returnUrl: string;
    isAuth: boolean;
    club: StM.IClubStoreState;
    isWaiverAccepted: boolean;
    isTermsAndConditionsAccepted: boolean;
    user: StM.IUserStoreState;
    match: any;
    location: any;
    history: any;

    openTab: (tab: string) => Promise<any>;
    getIsUniqueEmail: (email: string) => Promise<boolean>;
    login: (userName: string, password: string) => Promise<any>;
    signUp: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string,
        newsletterSubscribe: boolean, dateOfBirth: moment.Moment, isAvailableForInvitation: boolean) => Promise<any>;
    getCurrentUser: () => Promise<any>;
    getActives: () => Promise<any>;
    showAlert: (msgKey: string, msgType?: string, msg?: string) => Promise<any>;
    go: (url: string) => any;
    showSpinner: () => any;
    hideSpinner: () => any;
    closeAuthDialog: () => void;
    resendValidationEmail: (userName: string) => Promise<any>;
    openRecoveryPasswordDialog: () => Promise<any>;
    openWaiverDialog: () => Promise<any>;
    openDemographicInfoDialog: () => Promise<any>;
    openBillingInfoDialog: (returnUrl: string) => Promise<any>;
    logout: () => Promise<any>;
    setProfileCompletionConfirmation: (value: boolean) => void;
    closeAlert: () => void;
    reloadPage: () => void;
}

export interface AuthDialogState {
    errors: any;
    login: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    email: string;
    repeatEmail: string;
    newsletterSubscribe: boolean;
    isNeedValidateEmail: boolean;
    dateOfBirth: moment.Moment;
    isAvailableForInvitation: boolean;
}

class AuthDialogMobile extends React.Component<AuthDialogProps, AuthDialogState> {
    private utils = new SrvM.Utils();
    private validator = new SrvM.ValidationService();
    private formValidator = new SrvM.FormValidationService();

    private isWaitWaiver: boolean;
    private isWaiverAccepted: boolean;

    constructor(props: AuthDialogProps) {
        super(props);

        this.isWaitWaiver = false;
        this.isWaiverAccepted = false;

        this.state = {
            errors: null,
            login: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            email: '',
            repeatEmail: '',
            newsletterSubscribe: true,
            isNeedValidateEmail: false,
            dateOfBirth: null,
            isAvailableForInvitation: true
        };
    }

    componentDidUpdate(prevProps: AuthDialogProps) {
        const {
            isShow: prevIsShow,
            isWaiverAccepted: prevIsWaiverAccepted,
            isTermsAndConditionsAccepted: prevIsTermsAndConditionsAccepted,
            isAuth: prevIsAuth,
        } = prevProps;

        const {
            isShow,
            tab,
            isWaiverAccepted,
            isAuth,
            closeAuthDialog,
            logout,
        } = this.props;

        if (!prevIsShow && isShow) {
            this.isWaitWaiver = false;
            this.isWaiverAccepted = false;

            this.setState({
                errors: null,
                login: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
                email: '',
                repeatEmail: '',
                newsletterSubscribe: true,
                isNeedValidateEmail: false,
                dateOfBirth: null,
                isAvailableForInvitation: true,
            });
        }

        if (this.isWaitWaiver && isWaiverAccepted && !prevIsWaiverAccepted && !prevIsTermsAndConditionsAccepted) {
            this.isWaitWaiver = false;
            this.isWaiverAccepted = true;
            
            if (tab === StM.AuthDialogTabs.SignIn) {
                this.deferredLogin();
            } else if (tab === StM.AuthDialogTabs.SignUp) {
                this.deferredSignUp();
            }
        }

        if (this.isWaitWaiver && prevIsShow && !isShow) {
            logout();
        }

        if (!prevIsAuth && isAuth) {
            closeAuthDialog();
        }
    }

    public render() {
        if (this.props.isShow) {
            const isSignIn = this.props.tab.toUpperCase() === StM.AuthDialogTabs.SignIn.toUpperCase();
            const currentTabContent = isSignIn ? this.getSignInTab() : this.getSignUpTab();
            const signInTabClasses = ClassNames({ 'active': isSignIn }, 'mobile-col-6', 'tab');
            const signUpTabClasses = ClassNames({ 'active': !isSignIn }, 'mobile-col-6', 'tab');
            return (
                <ModalMobile classes="sign-in" dialogName={StM.DialogNames.Auth}>
                    <div className="modal-dialog-header">
                        <div className="tabs-wrapper mobile-row">
                            <div className="tabs-navigation">
                                <div className={signInTabClasses} onClick={(e) => { this.onTabClick(e, StM.AuthDialogTabs.SignIn) }}><a>Log In</a></div>
                                <div className={signUpTabClasses} onClick={(e) => { this.onTabClick(e, StM.AuthDialogTabs.SignUp) }}><a>Sign Up</a></div>
                            </div>
                        </div>
                    </div>
                    {currentTabContent}
                </ModalMobile>
            );
        }
        return null;
    }

    private getSignInTab() {
        return (
            <div className="modal-dialog-body sign-in-body container">
                <form className="form-wrapper" onSubmit={(e) => this.onLogInClick(e)}>
                    <div className="inputs-wrapper">
                        <Field name="login"
                            type="text"
                            className="email-login"
                            disabled={this.state.isNeedValidateEmail}
                            placeholder="Login"
                            autoComplete="on"
                            error={this.state.errors && this.state.errors.login}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="password"
                            type="password"
                            className="password-login"
                            placeholder="Password"
                            disabled={this.state.isNeedValidateEmail}
                            autoComplete="on"
                            error={this.state.errors && this.state.errors.password}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                    </div>
                    <FormError errors={this.state.errors} />
                    <div className="btns-wrapper">
                        <div className="log-in-wrapper">
                            {!this.state.isNeedValidateEmail && (<button className="btn-log-in btn-red btn-mobile" type="submit">LOG IN</button>)}
                            {this.state.isNeedValidateEmail && (<button className="btn-log-in btn-red btn-mobile" type="button" onClick={(e) => { this.onResendEmailClick(e) }}>RESEND EMAIL</button>)}
                        </div>
                    </div>
                </form>
                <div className="divider-line"></div>
                <div className="btn-forgot-pass-wrapper">
                    <span className="btn-forgot-pass" onClick={(e) => { this.onForgotClick(e) }}>Forgot password?</span>
                </div>
            </div>
        )
    }

    private getSignUpTab() {
        return (
            <div className="modal-dialog-body sign-up-body container">
                <div className="form-wrapper">
                    <div className="inputs-wrapper">
                        <Field name="firstName"
                            type="text"
                            label="First Name"
                            error={this.state.errors && this.state.errors.firstName}
                            maxLength={32}
                            value={this.state.firstName}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="lastName"
                            type="text"
                            label="Last Name"
                            error={this.state.errors && this.state.errors.lastName}
                            maxLength={32}
                            value={this.state.lastName}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="email"
                            type="text"
                            label="Email"
                            error={this.state.errors && this.state.errors.email}
                            maxLength={128}
                            autoComplete="off"
                            value={this.state.email}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="repeatEmail"
                            type="text"
                            label="Confirm Email"
                            error={this.state.errors && this.state.errors.repeatEmail}
                            maxLength={128}
                            autoComplete="off"
                            disabled={!this.state.email}
                            value={this.state.repeatEmail}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="password"
                            type="password"
                            label="Password"
                            error={this.state.errors && this.state.errors.password}
                            maxLength={128}
                            autoComplete="off"
                            value={this.state.password}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            error={this.state.errors && this.state.errors.confirmPassword}
                            maxLength={128}
                            autoComplete="off"
                            disabled={!this.state.password}
                            value={this.state.confirmPassword}
                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                        />
                        <Field name="dateOfBirth" label="Date of Birth" error={this.state.errors && this.state.errors.dateOfBirth}>
                            <DatePicker classes="input-item date-of-birth"
                                date={this.state.dateOfBirth}
                                onChange={(date) => this.handleFieldChange('dateOfBirth', this.utils.toDateOfBirth(date))}
                            />
                        </Field>
                        <div className="input-item checkbox-available">
                            <div className="checkbox-wrapper">
                                <label className="available-invite">
                                    <input type="checkbox" hidden defaultChecked={this.state.isAvailableForInvitation} onChange={(e: any) => this.handleFieldChange('isAvailableForInvitation', e.target.checked)} />
                                    <span>{Strings.AvailableForInvitationTitle}</span>
                                </label>
                                <div className="news-description">{Strings.AvailableForInvitationExplanation}</div>
                            </div>
                        </div>
                        <div className="input-item squash-news-item">
                            <label className="squash-news">
                                <input type="checkbox" hidden defaultChecked={this.state.newsletterSubscribe} onClick={(e: any) => this.handleFieldChange('newsletterSubscribe', e.target.checked)} />
                                <span>{Strings.getReceiveNewsTitle(this.props.club)}</span>
                            </label>
                        </div>
                    </div>
                    <FormError errors={this.state.errors} />
                    <div className="btns-wrapper">
                        <button className="btn-sign-up btn-red btn-mobile" onClick={(e) => this.onSignUpClick(e)}>NEXT</button>
                    </div>
                </div>
            </div>
        )
    }

    private onTabClick(e: any, tab: string) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.props.tab.toUpperCase() == tab.toUpperCase()) return false;
        this.setState({ ...this.state, errors: null });
        this.props.openTab(tab);
    }

    private loginValidate() {
        return {
            ...this.validator.validateRequired(this.state.login, 'login', 'Login'),
            ...this.validator.validatePassword(this.state.password, 'password'),
        };
    }

    private onLogInClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        const errors = this.loginValidate();
        if (this.validator.hasErrors(errors)) {
            this.setState({ ...this.state, errors });
            return false;
        }

        this.props.showSpinner();
        this.props.login(this.state.login, this.state.password)
            .then(() => {
                new SrvM.RequestCacheService().reset();
                return this.props.getCurrentUser();
            }).then((user: StM.IUserStoreState) => {
                if (user.isAcceptedWaiver) {
                    return this.deferredLogin();
                }
                if (this.isWaiverAccepted) {
                    return this.deferredLogin();
                } else {
                    this.isWaitWaiver = true;
                    this.props.openWaiverDialog();
                }
            }).then(() => {
                this.props.hideSpinner();
            }).catch((errorData) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (errorData && errorData.response) {
                    if (errorData.response.data && errorData.response.data.error === 'validate_email_error') {
                        this.setState({ ...this.state, isNeedValidateEmail: true });
                    }
                    if (errorData.response.status === 401)
                        this.setState({ ...this.state, errors: { server: 'The email or password is incorrect.' } });
                    else
                        this.setState({ ...this.state, errors: { server: errorData.response.data.error_description } });
                } else {
                    this.setState({ ...this.state, errors: { server: errorData ? errorData.toString() : 'Some error occurred.' } });
                }
            });
    }

    private deferredLogin() {
        this.props.showSpinner();
        return this.props.getCurrentUser()
            .then(() => {
                if (this.props.returnUrl) {
                    window.location.hash = decodeURIComponent(this.props.returnUrl);
                    this.props.reloadPage();
                } else {
                    this.props.go('/');
                }
                if (this.utils.getIsOpenDemographicInfoDialog(this.props.user)) {
                    this.props.openDemographicInfoDialog();
                }
                else if (!this.props.user.paymentProfileId ||
                    this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.Suspended ||
                    this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.NotInitialized) {
                    this.props.openBillingInfoDialog(null);
                }
                return this.props.hideSpinner();
            }).catch((errorData) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (errorData && errorData.response && errorData.response.data) {
                    if (errorData.response.data.error && errorData.response.data.error == 'validate_email_error') {
                        this.setState({ ...this.state, isNeedValidateEmail: true });
                    }
                    this.setState({ ...this.state, errors: { server: errorData.response.data.error_description } });
                } else {
                    this.setState({ ...this.state, errors: { server: errorData ? errorData.toString() : 'Some error occured.' } });
                }
            });
    }

    private onSignUpClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const errors = this.formValidator.validateSignUpForm(this.state, this.props.club);
        if (this.validator.hasErrors(errors)) {
            this.setState({ ...this.state, errors });
            return false;
        }

        if (this.isWaiverAccepted) {
            this.deferredSignUp();
        } else {
            this.isWaitWaiver = true;
            this.props.openWaiverDialog();
        }
    }

    private deferredSignUp() {
        this.props.showSpinner();
        class ThisNameIsAlreadyTakenError extends Error {
            constructor(message?: string) {
                super(message);
                this.message = message;
            }
        };

        const isUnderageUser = this.utils.checkIsBirthdayUnderage(this.state.dateOfBirth, this.props.club);
        this.props.getIsUniqueEmail(this.state.email)
            .then((isUniqueEmail: boolean) => {
                if (!isUniqueEmail) {
                    throw new ThisNameIsAlreadyTakenError('This email is already taken');
                }
            }).then(() => {
                return this.props.signUp(this.state.firstName, this.state.lastName, this.state.email, this.state.password, this.state.confirmPassword,
                    this.state.newsletterSubscribe, this.state.dateOfBirth, this.state.isAvailableForInvitation);
            }).then(() => {
                return Promise.all([this.props.getCurrentUser(), this.props.getActives()]);
            }).then(() => {
                this.props.hideSpinner();
                if (this.props.returnUrl) {
                    window.location.hash = decodeURIComponent(this.props.returnUrl);
                    this.props.reloadPage();
                }
                this.props.setProfileCompletionConfirmation(true);
                const formattedPhone = this.utils.formatPhone(this.props.club.phone)
                this.props.showAlert(
                    `Welcome to ${this.props.club.title}`,
                    StM.MessageTypes.Info, 
                    Strings.getCheckSpamMailMessage(this.props.club, formattedPhone, isUnderageUser)
                );

                this.props.closeAuthDialog();
                this.forceUpdate();
                return this.props.openDemographicInfoDialog();
            }).catch(error => {
                this.props.hideSpinner();
                this.props.closeAlert();
                let errorMessage = '';
                if (error instanceof ThisNameIsAlreadyTakenError) {
                    this.setState({ ...this.state, errors: { server: error.message } });
                } else {
                    if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                        errorMessage = error.response.data.exceptionMessage;
                    } else {
                        errorMessage = error ? error.toString() : 'Some error occured.';
                    }
                    this.setState({ ...this.state, errors: { server: errorMessage } });
                }
            });
    }

    private handleFieldChange(name: string, value: any) {
        let errors = !!this.state.errors && { ...this.state.errors };
        if (this.validator.hasErrors(errors)) delete errors[name];
        this.setState({
            ...this.state,
            [name]: value,
            errors
        });
    }

    private onForgotClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }

        this.props.openRecoveryPasswordDialog();
    };

    private onResendEmailClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.showSpinner();
        this.props.resendValidationEmail(this.state.login)
            .then(() => {
                this.props.closeAuthDialog();
                this.props.showAlert(StM.MessagesKey.ValidationEmailResending);
                this.props.hideSpinner();
            }).catch((error) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                let errorMessage = '';
                if (error && error.response && error.response.data) {
                    errorMessage = error.response.data;
                } else if (error && error.message) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error ? error.toString() : 'Some error occured.';
                }
                this.setState({ ...this.state, errors: { server: errorMessage } });
            });
    };
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        tab: state.dialogs.auth.tab
        , returnUrl: (state.dialogs.auth.returnUrl || '')
        , isAuth: state.app.isAuthorized
        , club: state.club
        , isWaiverAccepted: state.dialogs.waiver.isAccepted
        , isShow: state.dialogs.auth.isOpened && !state.dialogs.passwordChanging.isOpened
        , isTermsAndConditionsAccepted: state.dialogs.termsAndConditions.isAccepted
        , user: state.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        openTab: (tab: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: tab }))
        , openWaiverDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Waiver))
        , openRecoveryPasswordDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.PasswordRecovery))
        , login: (userName: string, password: string) => dispatch(ActM.AccountActions.login(userName, password))
        , getIsUniqueEmail: (email: string) => dispatch(ActM.AccountActions.getIsUniqueEmail(email))
        , signUp: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string, newsletterSubscribe: boolean, dateOfBirth: moment.Moment, isAvailableForInvitation: boolean) =>
            dispatch(ActM.AccountActions.signUp(firstName, lastName, email, password, confirmPassword, newsletterSubscribe, dateOfBirth, isAvailableForInvitation))
        , getCurrentUser: () => dispatch(ActM.UserActions.getCurrent())
        , getActives: () => dispatch(ActM.ActiveUsersActions.get(true))
        , showAlert: (msgKey: string, msgType: string = StM.MessageTypes.Success, msg?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: msgType, message: msg }))
        , go: (url: string) => dispatch(ActM.RouteActions.replace(url))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , closeAuthDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Auth))
        , openDemographicInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.DemographicInformation))
        , openBillingInfoDialog: (returnUrl: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo, { returnUrl: returnUrl }))
        , resendValidationEmail: (userName: string) => dispatch(ActM.AccountActions.resendValidationEmail(userName))
        , logout: () => dispatch(ActM.AccountActions.logout())
        , setProfileCompletionConfirmation: (value: boolean) => dispatch(ActM.UserActions.setProfileCompletionConfirmation(value))
        , closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
        , reloadPage: () => dispatch(ActM.BaseActions.reloadPage())
    };
}

const connectedAuthDialogMobile = connect(mapStateToProps, mapDispatchToProps)(AuthDialogMobile);
const AuthDialogMobileController = withRouter(connectedAuthDialogMobile);
export default AuthDialogMobileController;